import React from "react";
import { useNavigate } from "react-router";

export function HeaderNav(props) {
	return (
		<div className="header-nav">
			<div>
				<img
					src="assets/img/left.svg"
					className="left"
					alt="left previous-page"
					onClick={() => props.changePath("prev")}
				/>
			</div>
			<div>
				<img
					src="assets/img/right.svg"
					className="right"
					alt="right next-page"
					onClick={() => props.changePath("next")}
				/>
			</div>
		</div>
	);
}

export function BottomNav(props) {

	let navigate = useNavigate();

	function buttonClicked(path) {
		props.changePath(path);
	}
	
	return (
		<div className="bottom-nav">
			<span id="Home">
				<img
					src="assets/img/Home.svg"
					alt="Home"
					className=""
					onClick={() => buttonClicked(1)}
				/>
			</span>
			<span id="Works">
				<img
					src="assets/img/Works.svg"
					alt="Works"
					className=""
					onClick={() => buttonClicked(2)}
				/>
			</span>
			<span id="About">
				<img
					src="assets/img/About.svg"
					alt="About"
					className=""
					onClick={() => buttonClicked(3)}
				/>
			</span>
			{/* <span id="Workflow">
				<img
					src="assets/img/Workflow.svg"
					alt="Workflow"
					className=""
					onClick={() => buttonClicked(4)}
				/>
			</span> */}
			<span id="Contact">
				<img
					src="assets/img/Contact.svg"
					alt="Contact"
					className=""
					onClick={() => buttonClicked(4)}
				/>
			</span>
			<span id="Resume">
				<img
					src="assets/img/Resume.svg"
					alt="Resume"
					className=""
					onClick={() => navigate('/resume')}
				/>
			</span>
		</div>
	);
	
}
