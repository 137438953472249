import React, { useState , useEffect } from "react";

import Home from "./Home";
import Works from "./Works";
import About from "./About";
import Contact from "./Contact";
import Resume from "./Resume";
import { HeaderNav, BottomNav } from "../commonComponent/Nav";

import { rollPage } from "../util/animations";
import { useNavigate } from "react-router";


const pageClassTable = [0, "home", "work", "about", "contact", "resume"];
export default function Pages (props) {

	// let pageTable = [0, <Home />, <Works />, <About />, <Contact />];
	const [pageStates, setpageStates] = useState(false);
	let navigate = useNavigate();
	

	useEffect(() => {
		document.body.style["background-color"] = "#3F3D56";
		pageClassTable.slice(2)
			.forEach(
				(className) =>
					{document.body.querySelector(`.${className}`).style.display = "none";}
			);
	}, []);
	
		
	

	function changePage(page) {
		//
		// Get the current page from the page table

		if (page === "prev" || page === "next") {
			
			if (((props.currentPath > 0 && page === "next") || props.currentPath > 1) 
			    && (props.currentPath < 4 || (props.currentPath < 5 && page==="prev"))) {
				// console.log(page, props.currentPath);
				let curPage = document.body.querySelector(
					"." + pageClassTable[props.currentPath]
				);

				let direction = page;
				let adjPage =
					direction === "prev"
						? document.body.querySelector(
								"." + pageClassTable[props.currentPath - 1]
						  )
						: document.body.querySelector(
								"." + pageClassTable[props.currentPath + 1]
						  );
				pageClassTable
					.slice(1)
					.forEach(
						(className, index) =>
							{if (index !== props.currentPath){document.body.querySelector(`.${className}`).style.display = "none";} }
					);
				curPage.style.display = "flex";
				adjPage.style.display = "flex";
				rollPage(curPage, adjPage, direction);
			}
			if (props.currentPath === 4 && page === "next"){
				navigate("/resume");
			}
		} else if (page >= 1 && page <= 5) {
			let pagesInBetween = page - props.currentPath;
			let direction = pagesInBetween > 0 ? "next" : "prev";

			let counter = 0;
			pagesInBetween = Math.abs(pagesInBetween);

			while (pagesInBetween > 0) {
				let curPage, adjPage;

				if (direction === "next") {
					curPage = document.body.querySelector(
						"." + pageClassTable[props.currentPath + counter]
					);
					adjPage = document.body.querySelector(
						"." + pageClassTable[props.currentPath + 1 + counter]
					);
					
				} else {
					curPage = document.body.querySelector(
						"." + pageClassTable[props.currentPath - counter]
					);
					adjPage = document.body.querySelector(
						"." + pageClassTable[props.currentPath - 1 - counter]
					);
					
				}
				
				setTimeout(()=>{pageClassTable
					.slice(1)
					.forEach(
						(className, index) =>
							{if (index !== props.currentPath){document.body.querySelector(`.${className}`).style.display = "none";} }
					);
				curPage.style.display = "flex";
				adjPage.style.display = "flex";
				rollPage(curPage, adjPage, direction);}, 1000 * counter)
				

				counter++;
				pagesInBetween--;
			}
		}
		props.changePath(page);

		// if (
		// 	(page ==== "prev" && props.currentPath > 1) ||
		// 	(page ==== "next" && props.currentPath < 5)
		// 	//number six is the resume page and I will have to deal with that letter
		// ) {
		// }
		// console.log("@changepage after", props.currentPath);

		// else if ()
		// props.currentPath;
	}
	
	// Set the current path to atleast one incase the page is being loaded from the /pages url
	if (!pageStates) {
		props.changePath(1);
		setpageStates(true);
	}

	return (
		<div className="pages">
			<HeaderNav changePath={changePage} />
			<Home />
			<Works />
			<About />
			{/* <WorkFlow /> */}
			<Contact />
			<Resume changePath={changePage} />
			<BottomNav changePath={changePage} />
		</div>
	);
	
}

//psd:HR&MliH#92
