// utils and mandatory imports
import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import history from "./util/history";

//custom imports
import Pages from "./page/Pages";
import FirstPage from "./page/FirstPage";
import Resume from "./page/Resume";
import "./index.css";

// function NotFoundPage() {
// 	return (
// 		<div>
// 			<h1>$)$: page not found</h1>
// 		</div>
// 	);
// }

export default function App (props) {

	const [curpath, setcurpath] = useState(0);
	const [prevpath, setprevpath] = useState(0);

	useEffect(() => {
		if (curpath === 5) {
			history.push("/resume");
		}
	}, [curpath]);

	useEffect(() => {
		if (prevpath === 0) {
			history.push("/");
		}

	}, [prevpath]);
	

	function changePath(pathIndex) {
		//
		let previous = curpath;
		let current;

		
		if (pathIndex === "next" && curpath < 5) {
			current = curpath + 1;
		} else if (pathIndex === "prev" && prevpath >= 0) {
			current = curpath - 1;
		} else if (pathIndex >= 1 && pathIndex < 5) {
			current = pathIndex;
			previous = current - 1;
		}
		if (current){
			console.log(previous, current);
			setcurpath(current);
			setprevpath(previous);
		}
		
		
	}


	return (
		<Router>
			<Routes>
				<Route
					exact={true}
					path="/"
					element={
						<FirstPage changePath={changePath} />
					}
				/>
				<Route
					exact={true}
					path="/pages"
					element={
						<Pages
							{...props}
							changePath={changePath}
							currentPath={curpath}
							previousPath={prevpath}
						/>
					}
				/>
				<Route
					exact={true}
					path="/resume"
					element={
						<Resume />
					}
				/>

				<Route path="" element={<Navigate to="/pages" />} />
			</Routes>
		</Router>
	);
	
}
