import React, { useEffect } from "react";
import { useNavigate } from "react-router";

export default function Resume ({changePath}) {

	let navigate = useNavigate();

	useEffect(() => {
		document.body.style["background-color"] = "white";
	}, []);

	return (
		<div className="resume back-cover">
			<div className="backpage">
				<img
					src="assets/img/completeback.png"
					alt="Back cover"
					onClick={() => navigate("/")}
				/>
				<a href="https://docs.google.com/document/d/e/2PACX-1vSwDhljcm9T0j9ZwChTCA_9HhkUB0K33AOxMHcuwabeKmlosPNMXfEzB0WRXCJ1TFfPQuw9Nh5tdCHj/pub" className="resume_button">Complete Resume</a>
			</div>
			
		</div>
	);
	
}
