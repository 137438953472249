import React, { useEffect } from "react";
import NoteBookPages from "../commonComponent/NoteBookPages";
import kwesforms from 'kwesforms'



export default function Contact () {

	useEffect(() => {
	  kwesforms.init()
	}, []);
	
	
	let topLeft = (
		<div className="top left single-page">
			<div className="leftpage-content">
				<img src="assets/img/profile.svg" alt="my avatar" />
				<div className="address">
					<a href="mailto:ehmyitayew@college.harvard.edu">Email</a>
				</div>
				<div className="address">
					<a href="https://www.linkedin.com/in/hileamlak-mulugeta-yitayew-a8b43317a/">LinkedIn</a>
				</div>
				<div className="address">
					<p>+18579997480</p>
				</div>
			</div>
		</div>
	);

	let bottomRight = (
		<div className="bottom right single-page">
			<form
				id="fcf-form-id"
				className="fcf-form-class kwes-form"
				method="post"
				action="https://kwesforms.com/api/foreign/forms/4PLxSdIDprAB4TEEPQpD">
				<div className="fcf-form-group">
					<label htmlFor="Name" className="fcf-label">
						Your name
					</label>
					<div className="fcf-input-group">
						<input
							type="text"
							id="Name"
							name="Name"
							className="fcf-form-control"
							required
						/>
					</div>
				</div>

				<div className="fcf-form-group">
					<label htmlFor="Email" className="fcf-label">
						Your email address
					</label>
					<div className="fcf-input-group">
						<input
							type="email"
							id="Email"
							name="Email"
							className="fcf-form-control"
							required
						/>
					</div>
				</div>

				<div className="fcf-form-group">
					<label htmlFor="Message" className="fcf-label">
						Your message
					</label>
					<div className="fcf-input-group">
						<textarea
							id="Message"
							name="Message"
							className="fcf-form-control"
							rows="6"
							maxLength="3000"
							required></textarea>
					</div>
				</div>

				<div className="fcf-form-group">
					<button
						type="submit"
						id="fcf-button"
						className="fcf-btn fcf-btn-primary fcf-btn-lg fcf-btn-block">
						Send Message
					</button>
				</div>
			</form>
		</div>
	);
	let values = {
		className: "contact double-page",
		topLeft: topLeft,
		bottomRight: bottomRight,
	};

	return NoteBookPages(values);
	
}
