import React from "react";
import NoteBookPages from "../commonComponent/NoteBookPages";


function WorkDisplayCard({src, title, description, source_code, online_location, link, source_location, source_text}){
	return (
		<div className="display-work">
			<div className="image" style={{"backgroundImage":`url(${src})`}}></div>
			<div className="discription">
				<h3>{title}</h3>
				<p>{description}</p>
				<p>{source_code} <a href={source_location}>{source_text}</a></p>
				<a href={link}>{online_location}</a>
			</div>
		</div>
	)
}

function SkillsCard({cls, src, alt, description}){
	return (
		<div className={`card ${cls}`}>
			<img src={src} className="card-images" alt={alt} />
			<p className="card-discription">
				{description}
			</p>
		</div>
	)
}

export default function Works () {
	
	let skills = [
		{
			cls:"speciality",
			src:"assets/img/responsive.svg",
			alt:"responsiveness",
			description:"I make Fast, responsive, cross-browser compatible, and SEO friendly websites with up-to-date code practices!"
		},
		{
			cls:"technologies",
			src:"assets/img/technologies.svg",
			alt:"react icon",
			description:"I have a ground knowledge in Html, CSS, and Js. I can work with any framework just give me 5 days prior. I already have experience in tools like wordpress, Scss, react and bootstrap."
		},
		{
			cls:"versatility",
			src:"assets/img/versatility.svg",
			alt:"multiple nodes",
			description:"Scripts, webservers, speed depmanding computational programs: I write them all. I have recent experiences in c, python, bash and javascript."
		},
		{
			cls:"adaptability",
			src:"assets/img/deliver1.svg",
			alt:"phone with a check mark",
			description:"I can adapt to organizaion practices and work in any group. I try my best to deliver on time."
		}
	]
	let works = [
		{
			src:"assets/img/chessbytes.png",
			title:"Chessbytes.io",
			description:"Chessbytes is a platform where you can immortalize your chess moments. It could be a position you like, or the whole game, if it meant something to you, chessbytes can help you mint an NFT on some of the most famous NFT blockchains. If you want, you can trade them in the future.",
			source_code:"This project is builit with (flask, brownie)-python, (web3, ethereum, jquery)-javascript and solidity. It is hosted on a droplet on digital ocean.",
			online_location:"Hosted @chessbytes.io",
			link:"https://chessbytes.io"
		},
		{
			src:"assets/img/game91.jpg",
			title:"Game91 bot",
			description:"Game 91 is a telegram bot that can interactively be used by users in a group to play the card game 91. 91 is a fun game which was played in a mathcamp I facilitated. In 2021 there was a virtual version I participated in. I created this telegram bot to recreate the excitment in future online versions of the camp.  ",
			source_code:"This project is builit with python. The source code could be found ",
			source_text:"Here",
			source_location:"https://github.com/hileamlakB/Game_91_tg_bot",
			online_location:"Hoted @telegram",
			link:"https://t.me/ehm_game_91_bot"
		},
		{
			src:"assets/img/adultfree.png",
			title:"Adult-free movie poster",
			description:"This website is uses the TDMI API to showcase recent movies with no adult content.",
			source_code:"It is built with with react. The source code could be found",
			source_text:"Here",
			source_location:"https://github.com/hileamlakB/Clone_wars/tree/master/adult-free-movie-site",
			online_location:"Hosted @heroku",
			link:"https://adult-free.web.app/"
		},
		{
			src:"assets/img/stemyear.png",
			title:"A year book website",
			description:"This is a website where we documented our class experiences through the last 4 years. It presentes different pictures albums using cool animations.",
			source_code:"This project is built with vanilla js with the help of gsap liberary for the animations. The source code could be found",
			source_text:"Here",
			source_location:"https://github.com/hileamlakB/STEM2012YearBook",
			online_location:"Hosted @githubpages",
			link:"https://hileamlakb.github.io/STEM2012YearBook/"
		},
		{
			src:"assets/img/airbnb.png",
			title:"Airbnb backend clone",
			description:"This is project is a remake of what the airban backend might look like. This project provides an adminstrative console to controle the backend and the database for a website like Airbnb. It utilizes ORMs to do so.",
			source_code:"It is built with python and the cmd liberary. The source code could be found",
			source_text:"Here",
			source_location:"https://github.com/hileamlakB/AirBnB_clone",
		}
	]
	
	
	let topLeft = (
		<div className="top left single-page">
			<div className="cards">
				{
					skills.map((skill, index)=><SkillsCard key={index} {...skill}/>)
				}					
			</div>
		</div>
	);

	let bottomRight = (
		<div className="bottom right single-page">
			{
				works.map((work, index)=> <WorkDisplayCard key={index} {...work}/>)
			}
			<div className="display-work">
				<div className="image" style={{"backgroundImage":`url(assets/img/mix.jpg)`}}></div>
				<div className="discription">
					<p>Photo by <a href="https://unsplash.com/@yogidan2012?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Daniele Levis Pelusi</a> on <a href="https://unsplash.com/s/photos/mix?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
					<h3>Other works</h3>
					
					<p>Here are some other projects I have been working on.</p>
					<ul>
						<li>
							<h5>Money Interpreter</h5>
							<p>Monty is a stack based simple programming language built in c. <a href="https://github.com/hileamlakb/monty_interpreter">source code</a> </p>
						</li>
						<li>
							<h5>GBK</h5>
							<p>A bourne again like shell program built in c. <a href="https://github.com/hileamlakb/GBK">source code</a> </p>
						</li>
						<li>
							<h5>X86_64cachedio </h5>
							<p>A simple liberary that provides speciallized io cache for x86_64 architecture. It is faster for sequential readings.<a href="https://github.com/hileamlakB/x86_64cachedio">source code</a> </p>
						</li>


						
					</ul>
					
					
				</div>
			</div>
			<div className="display-work">
				<div className="image" style={{"backgroundImage":`url(assets/img/other_websites.jpg)`}}></div>
				<div className="discription">
					<h3>Random websites</h3>
					
					<p>Here are some other plain websites I built over the years</p>
					<ul>
						<li>
							<h5><a href="https://hileamlakb.github.io/alx-website/index.html">Resume template</a></h5>
						</li>
						<li>
							<h5><a href="https://hileamlakb.github.io/virtualMathCamp/">Virtual math camp</a></h5>
						</li>
						<li>
							<h5><a href="https://hileamlakb.github.io/Portfolio_rojects/JobOportunity/">Highering landing page</a></h5>
							
						</li>


						
					</ul>
					
					
				</div>
			</div>
		</div>
	);

	let floatingBox = (
		<div className="floating-box">
			<div className="floating-top"></div>
			<div className="floating-bottom"></div>
		</div>
	);

	let values = {
		className: "work double-page",
		topLeft: topLeft,
		bottomRight: bottomRight,
		floatingBox: floatingBox,
	};

	return NoteBookPages(values);
	
}
