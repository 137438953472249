import React from "react";
import NoteBookPages from "../commonComponent/NoteBookPages";

class RecurringTimer {
	constructor(callback, delay) {
		this.timerId = null;
		this.start = null;
		this.remaining = delay;
		this.callback = callback;
	}

	pause() {
		window.clearTimeout(this.timerId);
		this.remaining -= new Date() - this.start;
	}

	resume() {
		this.start = new Date();
		this.timerId = window.setTimeout(() => {
			this.remaining = this.delay;
			this.resume();
			this.callback();
		}, this.remaining);
	}
}

function colorify(textarea, text) {
	let codeArr = text.split("");

	let colorCode = {
		while: "#6C63FF",
		in: "#07C5AE",
		Exception: "#6C63FF",
		try: "#FF6584",
		except: "#FF6584",
		True: "#FFD037",
		as: "#FFD037",
		VAR: "#41FE81",
		FUNC: "#BC8487",
	};

	let cursor = document.createElement("SPAN");
	cursor.innerText = "|";
	cursor.className = "cursor";
	cursor.style.color = "#ff7800";
	cursor.style["font-size"] = "1.2em";
	cursor.style["font-weight"] = "bold";

	setInterval(() => {
		cursor.style.opacity = cursor.style.opacity === "0" ? "1" : "0";
	}, 500);

	let arraycounter = 0;
	let color = "none";
	let writingAnim = new RecurringTimer(() => {
		if (arraycounter >= codeArr.length) {
			//
			clearInterval(writingAnim);
		} else {
			//
			//check if the string is letter if it is add it to the word and
			//also store the starign inded as it will be use full later
			let word = "",
				startingIndex = arraycounter,
				endingcounter = arraycounter;

			while (/[a-zA-Z]/.test(codeArr[arraycounter])) {
				word += codeArr[arraycounter];
				endingcounter += 1;
				arraycounter += 1;
			}

			if (codeArr[arraycounter] === "(") {
				color = colorCode.FUNC;
			} else {
				color = colorCode[word];
			}

			arraycounter = startingIndex;

			writingAnim.pause();

			let wordTypeAnim = setInterval(() => {
				if (arraycounter >= endingcounter) {
					writingAnim.resume();
					clearInterval(wordTypeAnim);
				}
				let pre = document.createElement("PRE");
				pre.innerText = codeArr[arraycounter];
				if (codeArr[arraycounter] === ")") {
					color = colorCode.FUNC;
				} else if (
					codeArr[arraycounter] === ";" ||
					codeArr[arraycounter] === "=" ||
					codeArr[arraycounter] === ":"
				) {
					color = "white";
				}

				pre.style.color = color;

				textarea.appendChild(pre);

				textarea.appendChild(cursor);

				arraycounter += 1;
			}, 100);
		}
	}, 100);
	writingAnim.resume();
}

export default class Home extends React.Component {
	constructor(props) {
		super(props);
		this.code = `

 Alive = True;

 while Alive:

   try:
    hard();

   except Exception as in life:
    jumpOverIt();
    tryagain();
`;
	}
	componentDidMount() {
		let scale = 3;
		try {
			document.body.querySelector(".home").style["transform-origin"] = `20% 25%`;
		} catch (error) {}
		let scaleAnimation = setInterval(() => {
			if (scale <= 1) {
				clearInterval(scaleAnimation);
			}
			try {
				document.body.querySelector(".home").style.transform = `scale(${scale})`;
			} catch (error) {
				if (error.name === TypeError) {
					clearInterval(scaleAnimation);
				}
			}

			scale -= 0.1;
		}, 100);

		colorify(document.body.querySelector(".code-area"), this.code);
	}
	render() {
		let topLeft = (
			<div className="top left single-page">
				<div className="leftpage-content">
					<img src="assets/img/avatar.svg" alt="male avatar" />
					<img src="assets/img/textplaceholder.svg" alt="sample text placeholder" />
					<blockquote>We Imagine, We Create!!</blockquote>
				</div>
			</div>
		);
		//textares line number
		let lineNumber = [];
		let totalLines = 13;
		for (let x = 1; x <= totalLines; x++) {
			lineNumber.push(x);
		}
		let bottomRight = (
			<div className="bottom right single-page">
				<div className="vscode">
					<div className="titlebar">
						{/* import images as one file to reduce server request and use positioning to get the images */}

						<svg
							className="close-button"
							width="22"
							height="22"
							viewBox="0 0 22 22"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M22 11C22 7.963 20.768 5.211 18.778 3.222C16.788 1.233 14.037 0 11 0C7.963 0 5.211 1.232 3.222 3.222C1.233 5.212 0 7.963 0 11C0 14.037 1.232 16.789 3.222 18.778C5.212 20.767 7.963 22 11 22C14.037 22 16.789 20.768 18.778 18.778C20.767 16.788 22 14.037 22 11ZM11 10C12.63 8.37 11 12.486 11 10C13.486 10 9.37 11.63 11 10C12.63 11.63 8.514 10 11 10C11 12.486 9.37 8.37 11 10C9.37 11.63 11 7.514 11 10C8.514 10 12.63 8.37 11 10C11 7.514 12.63 11.63 11 10Z"
								fill="#FF6584"
							/>
							<path
								d="M13.8125 6L11 8.8125L8.1875 6L6.5 7.6875L9.3125 10.5L6.5 13.3125L8.1875 15L11 12.1875L13.8125 15L15.5 13.3125L12.6875 10.5L15.5 7.6875L13.8125 6Z"
								fill="white"
							/>
						</svg>
						<svg
							className="minimize-button"
							width="22"
							height="22"
							viewBox="0 0 22 22"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M22 11C22 7.963 20.768 5.211 18.778 3.222C16.788 1.233 14.037 0 11 0C7.963 0 5.211 1.232 3.222 3.222C1.233 5.212 0 7.963 0 11C0 14.037 1.232 16.789 3.222 18.778C5.212 20.767 7.963 22 11 22C14.037 22 16.789 20.768 18.778 18.778C20.767 16.788 22 14.037 22 11ZM11 11C12.63 9.37 11 13.486 11 11C13.486 11 9.37 12.63 11 11C12.63 12.63 8.514 11 11 11C11 13.486 9.37 9.37 11 11C9.37 12.63 11 8.514 11 11C8.514 11 12.63 9.37 11 11C11 8.514 12.63 12.63 11 11Z"
								fill="#FFD037"
							/>
							<path
								d="M7 12H15C15.552 12 16 11.552 16 11C16 10.448 15.552 10 15 10H7C6.448 10 6 10.448 6 11C6 11.552 6.448 12 7 12Z"
								fill="white"
							/>
						</svg>

						<p className="title"></p>
					</div>
					<div className="statusbar">
						<p>hilea.py</p>
					</div>
					<div className="textarea">
						<div className="number-bar">
							<ol>
								{lineNumber.map((item) => (
									<li key={item}>{item}</li>
								))}
							</ol>
						</div>
						<div className="code-area"></div>
					</div>
					<div className="terminal"></div>
				</div>
			</div>
		);
		let values = {
			className: "home double-page",
			topLeft: topLeft,
			bottomRight: bottomRight,
			style: { transform: "scale(3)" },
		};

		return NoteBookPages(values);
	}
}
