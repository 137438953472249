export function rollPage(curPage, adjPage, direction) {
	let adjCover, curCover;

	if (direction === "next") {
		adjCover = adjPage.querySelector(".left");
		curCover = curPage.querySelector(".right");
	} else if (direction === "prev") {
		adjCover = adjPage.querySelector(".right");
		curCover = curPage.querySelector(".left");
	}

	if (direction === "next") {
		let angle = 0;

		Object.assign(curCover.style, { "transform-origin": "left" });

		let pageRoleInterval = setInterval(() => {
			if (angle >= 70) {
				clearInterval(pageRoleInterval);
				curPage.style["z-index"] = 10;
				adjPage.style["z-index"] = 11;
				Object.assign(adjCover.style, {
					"transform-origin": "right",
					transform: "rotateY(-90deg) skewY(-80deg)",
					"transform-style": "preserve-3d",
				});

				let adjRotation = -85;
				let skewAngle;

				let adjCoverRoleInetrval = setInterval(() => {
					skewAngle = adjRotation > -15 ? adjRotation : -15;

					if (adjRotation >= 0) {
						//Stop the animation if it has rotated to its exact position
						clearInterval(adjCoverRoleInetrval);
						Object.assign(curCover.style, {
							transform: "rotateY(0deg) skewY(0deg)",
						});
					}

					adjCover.style.transform = `rotateY(${adjRotation}deg) skewY(${skewAngle}deg)`;
					adjRotation += 5;
				}, 50);
			}

			// Controle the angle during the rolling duration
			if (angle >= 50) {
				// Only have a maximum skewY of 50deg
				curCover.style.transform = `skewY(50deg)  rotateY(${angle + 20}deg)`;
			} else {
				curCover.style.transform = `skewY(${angle}deg) rotateY(${angle + 20}deg)`;
			}

			angle += 5;
		}, 50);
	} else if (direction === "prev") {
		let angle = 0;

		Object.assign(curCover.style, { "transform-origin": "right" });

		let pageRoleInterval = setInterval(() => {
			if (angle >= 70) {
				clearInterval(pageRoleInterval);
				curPage.style["z-index"] = 10;
				adjPage.style["z-index"] = 11;
				Object.assign(adjCover.style, {
					"transform-origin": "left",
					transform: "rotateY(-90deg) skewY(-80deg)",
					"transform-style": "preserve-3d",
				});

				let adjRotation = -85;
				let skewAngle;

				let adjCoverRoleInetrval = setInterval(() => {
					skewAngle = adjRotation > -15 ? adjRotation : -15;

					if (adjRotation >= 0) {
						//Stop the animation if it has rotated to its exact position
						clearInterval(adjCoverRoleInetrval);
						Object.assign(curCover.style, {
							transform: "rotateY(0deg) skewY(0deg)",
						});
					}

					adjCover.style.transform = `rotateY(${adjRotation}deg) skewY(${-skewAngle}deg)`;
					adjRotation += 5;
				}, 50);
			}

			// Controle the angle during the rolling duration
			if (angle >= 50) {
				// Only have a maximum skewY of 50deg
				curCover.style.transform = `skewY(-50deg)  rotateY(${angle + 20}deg)`;
			} else {
				curCover.style.transform = `skewY(${-angle}deg) rotateY(${angle + 20}deg)`;
			}

			angle += 5;
		}, 50);
	}
}
