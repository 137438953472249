import React from "react";

export default function NoteBookPages(values) {
	return (
		<div className={values.className} style={values.style}>
			{values.floatingBox}
			{values.topLeft}
			<div className="page-break">
				<img className="break-hing" src="assets/img/hing.png" alt="decorating hing"/>
			</div>
			{values.bottomRight}
			
		</div>
	);
}
