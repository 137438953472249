import React, { Component } from "react";
import NoteBookPages from "../commonComponent/NoteBookPages";

export default class About extends Component {
	render() {
		let topLeft = (
			<div className="top left single-page">
				<div className="leftpage-content">
					<img src="assets/img/profile.svg" alt="my avatar" />
					<p className="info about_me">
						I am Hileamlak, a rising Software developer currently studying computer science at Harvard.
					</p>
						

					<p>
						I would describe my self as one curious being trying understand the world. I am always on the lookout to
						feed my curiousity. One way I do this is by learning and working with new technologies. Some of my previous
						experiences includes fullstack development, blockchain development, and automation scripting.
						I can also quickly pickup new tools and work with groups. 
					</p>
					<p>
						I can't wait to work with you next.{" "}
					</p>
				</div>
			</div>
		);

		let bottomRight = (
			<div className="bottom right single-page">
				<section className="designing">
					<h2>Designing</h2>
					<div className="imgs">
						<img className="talents" src="assets/img/photoshop.png" alt="photoshop icon" />
						<img className="talents" src="assets/img/figma.jpg" alt="figma icon" />
					</div>
				</section>

				<section className="">
					<h2>Markup/Languages</h2>
					<div className="imgs">
						<img className="talents" src="assets/img/html5.png" alt="html 5" />
						<img className="talents" src="assets/img/css3.png" alt="css 3" />
						<img className="talents" src="assets/img/javascript.png" alt="javascript" />
						<img className="talents" src="assets/img/python.png" alt="python programing language" />
						<img className="talents" src="assets/img/c.png" alt="c programing language" />
						<img className="talents" src="assets/img/bash.png" alt="bash scripting langaue" />
					</div>
				</section>

				<section className="frame-works">
					<h2>Frame works and others</h2>
					<div className="imgs">
						<img className="talents" src="assets/img/bootstrap.png" alt="bootstrap framework" />
						<img className="talents" src="assets/img/jquery.png" alt="jquery" />
						<img className="talents" src="assets/img/react.png" alt="react framework" />
						<img className="talents" src="assets/img/gsap.png" alt="gsap liberary" />
						<img className="talents" src="assets/img/sass.png" alt="sass extension" />
						<img className="talents" src="assets/img/docker.png" alt="docker container logo" />
						<img className="talents" src="assets/img/github.png" alt="github logo" />
						<img className="talents" src="assets/img/heroku.png" alt=" heroky logo" />
						<img className="talents" src="assets/img/webpack.png" alt="webpack logo"/>
						<img className="talents" src="assets/img/firebase.png" alt="firebase logo" />
						<img className="talents" src="assets/img/digitalocean.png" alt="digital ocean logo"/>
						<img className="talents" src="assets/img/aws.png" alt="amazon webservices logo" />
						<img className="talents" src="assets/img/more.png" alt="And more" />
					</div>
				</section>
			</div>
		);
		let values = {
			className: "about double-page",
			topLeft: topLeft,
			bottomRight: bottomRight,
		};

		return NoteBookPages(values);
	}
}
